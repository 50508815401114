<template>
	<div id="retrieve">
		<div class="form-group text-center">
			<h2 v-focus tabindex="0">取回帳密</h2>
		</div>
		<div class="form-horizontal">
			<template v-for="v in ['newPassword1', 'newPassword2']">
				<formdrf
					:model_info="model_infos[v]"
					:field="v"
					v-model="infos[v]"
					:key="v"
					@keyup.enter.native="reset_password()"
				></formdrf>
			</template>
			<div class="form-group">
				<div class="col-sm-3 col-sm-offset-2">
					<button
						class="form-control btn btn-primary"
						@click="reset_password()"
					>
						送出
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { userAction } from "@/api/user";
import formdrf from "@/components/form.vue";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			infos: {
				newPassword1: "",
				newPassword2: ""
			},
			model_infos: {
				newPassword1: {
					label: "新密碼",
					type: "password",
					required: true
				},
				newPassword2: {
					label: "確認新密碼",
					type: "password",
					required: true
				}
			}
		};
	},
	metaInfo: {
		title: "重設密碼"
	},
	methods: {
		reset_password() {
			if (!(this.infos.newPassword1 === this.infos.newPassword2)) {
				return this.$root.$message.open({
					status: 400,
					message: "新密碼與確認新密碼不符"
				});
			}
			userAction
				.verifyResetPassword({
					code: this.$route.query.token,
					newPassword1: this.infos.newPassword1,
					newPassword2: this.infos.newPassword2
				})
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "成功重設密碼"
					});
				})
				.then(() => {
					this.$router.push({ name: "login" });
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
